import { Injectable } from '@angular/core';
import { Observable, from, map, catchError, of } from 'rxjs';
import { listUsers } from '../../../../graphql/queries';
import { UserDto } from '../dto/dto';
import { generateClient } from 'aws-amplify/api';
import { DataService } from '../../../shared/service/data.service';
const client = generateClient();
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _dataServie: DataService) {}
  getUserByEmail(email: string): Observable<UserDto | null> {
    return from(
      client.graphql({
        query: listUsers,
        variables: {
          filter: {
            email: {
              eq: email,
            },
          },
        },
      })
    ).pipe(
      map((result) => {
        if (result.data.listUsers.items[0]) {
          return <UserDto>result.data.listUsers.items[0];
        }
        return null;
      }),
      catchError((error) => {
        return of(null);
      })
    );
  }

  getUsersClient(): Observable<UserDto[] | null> {
    const systemcode = this._dataServie.getSystemCodeId('User', 'Active');
    return from(
      client.graphql({
        query: listUsers,
        variables: {
          filter: {
            userSystemCodeId: {
              eq: systemcode,
            },
          },
        },
      })
    ).pipe(
      map((result) => {
        if (result.data.listUsers.items) {
          return <UserDto[]>result.data.listUsers.items;
        }
        return null;
      }),
      catchError((error) => {
        return of(null);
      })
    );
  }
}
